import React, { Suspense } from "react";
import "./App.scss";
import Spinner from "./components/Spinner/Spinner";
const Main = React.lazy(() => import("./Main"));

function App() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <Main />
      </Suspense>
    </>
  );
}

export default App;
