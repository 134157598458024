import React from "react";
import "./Spinner.scss";

const Spinner = props => (
  <div className="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Spinner;
